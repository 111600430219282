@import './_vars';
@import './fonts';

* {
  color: $gray;
}

.public-route {
  background-image: url('../images/landing-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

p {
  margin: 0;
}

.base {
  font-size: 16px;
}

.flex-grow-1 {
  flex-grow: 1;
}

.user-select-none {
  user-select: none;
}

.fw-semi-bold {
  font-weight: $font-weight-semi-bold;
}

.accordion-button {
  font-weight: 700;
  font-size: $lead-font-size;
}

.accordion-header {
  box-shadow: 0 -4px 15px 1px rgba(0, 0, 0, 0.11) !important;
}

.nav-hover:hover {
  background-color: $secondary-300;
}

.footer {
  height: 61px;
}

.header {
  height: 80px;
}

.settings-dropdown {
  min-width: 188px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.notifications-dropdown {
  min-width: 320px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.header-collapse {
  transition: none;
}

.header-menu-shadow {
  box-shadow: 2px 0 7px 6px rgba(0, 0, 0, 0.15);
}

.border-bottom-right-radius {
  border-bottom-right-radius: 5px;
}

.pointer {
  cursor: pointer;
}

.divider-horizontal {
  border-bottom: 1px solid $light-gray-25;
}

.divider-vertical {
  border-right-style: solid;
  border-right-width: 1px;
}

.outline-solid {
  outline-style: solid;
}

.outline-secondary {
  outline-color: $secondary;
}

.outline-width-2 {
  outline-width: 2px;
}

.dashboard-card-shadow {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
}

.dashboard-card-shadow-active {
  box-shadow: 0 0 20px 1px rgba(0, 220, 245, 0.66);
}

.rotate-180 {
  transform: rotateY(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.nav-tabs .nav-item {
  font-size: $lead-font-size;
  .nav-link {
    border: none;
    color: $gray;
  }
  .active {
    border-bottom: 5px solid $info;
    font-weight: bold;
    color: $primary;
  }
}

.statement-row {
  border-color: $secondary-400;
}

.statement-row:hover {
  background-color: $secondary-500;
  box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.08);
  border-color: $transparent;
  font-weight: 600;
}

.card-shadow {
  box-shadow: 2px 3px 14px 1px rgba(0, 0, 0, 0.07);
}

.card-shadow-secondary {
  box-shadow: 1px 1px 11px 13px rgba(0, 220, 245, 0.05);
}

.card-header-rounded {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.form-control {
  height: 48px;
  font-size: 18px;
  font-family: $red-hat;
  font-weight: $font-weight-semi-bold;
  background-color: $secondary-400;
  outline: 0.5px solid $primary;
  border: none;
  &::placeholder {
    color: $gray;
  }
}

.form-control:focus {
  outline: 2px solid $secondary-300;
  box-shadow: none;
}

.custom-dropdown-toggle {
  height: 48px;
  font-size: 18px;
  font-family: $red-hat;
  font-weight: $font-weight-semi-bold;
  background-color: $secondary-400;
  // outline: 0.5px solid $primary !important;

  &::after {
    border: none;
  }

  &:focus {
    background-color: $light;
  }

  &:hover {
    background-color: $secondary-400;
  }
}

.custom-dropdown-shadow {
  box-shadow: 0 -1px 6px 1px rgba(0, 0, 0, 0.25);
}

.form-switch {
  display: flex;
  align-items: center;
  .form-check-input {
    height: 16px;
    width: 30px;
    margin-top: 0%;
    border-color: $light-gray;
    background-image: url('../images/switch-inner.svg') !important;
  }
  .form-check-input:checked {
    background-color: $light !important;
    background-image: url('../images/switch-inner-active.svg') !important;
  }

  .form-check-input:focus {
    outline: 0;
  }
}

.settings-switch {
  display: flex;
  align-items: center;
  .form-check-input {
    height: 16px;
    width: 30px;
    margin-top: 0%;
    border-color: $light-gray;
    background-color: $light-gray;
    background-image: url('../images/settings-switch-inner.svg') !important;
  }
  .form-check-input:checked {
    background-color: $primary !important;
    border-color: $primary;
    background-image: url('../images/settings-switch-inner-active.svg') !important;
  }

  .form-check-input:focus {
    outline: 0;
  }
}

.btn-primary,
.btn-primary:focus,
.btn-primary:hover {
  outline: 0.5px solid $primary !important;
  border: none;
  color: $primary;
  background-color: $light !important;
  font-weight: $font-weight-semi-bold;
  font-size: 16px;

  &:active {
    outline: 3px solid $secondary-400 !important;
    color: $primary;
  }
}

.btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
  outline: 0.5px solid $primary !important;
  border: none;
  color: $light;
  background-color: $primary !important;
  font-weight: $font-weight-semi-bold;
  font-size: 16px;

  &:active {
    outline: 3px solid $secondary-400 !important;
    color: $light;
  }
}

.btn-modal, .btn-modal:focus, .btn-modal:hover {
  outline: 0.5px solid $primary !important;
  border: none;
  color: $primary;
  background-color: $secondary-400 !important;
  font-weight: $font-weight-semi-bold;
  font-size: 16px;
  
  &:active {
    outline: 3px solid $secondary-400 !important;
    color: $primary;
  }
}

.public-route-logo {
  position: absolute;
  top: 6%;
  left: 8%;
}

.sign-out-logo {
  width: 108px;
  height: 122px;
}

.code-input {
  width: 48px;
  height: 64px;
  text-transform: uppercase;
  background-color: $light;
  border-width: 0.5px;

  &:focus {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.4);
    outline: 1px solid $primary-200;
  }
}

.requestvideochat-button {
  color: $gray;
  font-weight: $font-weight-semi-bold;
  stroke: $gray;
  fill: $gray;

  &:active {
    color: $primary;
    font-weight: 700;
    stroke: $primary;
    fill: $primary;
    outline: 2px solid $primary-200;
  }

  .requestvideochat-button-inner {
    color: inherit;
  }

  p {
    color: inherit;
    font-weight: inherit;
    user-select: none;
  }

  .requestvideochat-icon {
    stroke: inherit;
  }

  .requestvideochat-chevron {
    stroke: inherit;
    fill: inherit;
    justify-self: right;
  }
}

.z-50 {
  z-index: 50;
}

.z-51 {
  z-index: 51;
}

.security-select-input {
  min-width: 500px;
  border: none;
  // height: 40px;

  .security-select-toggle {
    border: none;
    outline: none;
    box-shadow: 0 -1px 6px 1px rgba(0, 0, 0, 0.1);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $light;
    color: $gray;
  }
}

.security-text-input {
  outline: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  height: 40px;
}

.security-input-line {
  position: absolute;
  background-color: $gray;
  height: 0.25px;
  width: 96%;
  bottom: 20%;
  left: 2%;
}

.loan-progress-bar {
  height: 24px;
  .progress-bar {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    transition: none;
  }
}

.loan-radio {
  .form-check-input {
    background-color: $primary-200;
    border-color: $light-gray;
  }

  .form-check-input:checked {
    border-color: $secondary; /* Border color for the checked radio */
    background-image: url('../images/switch-inner-active.svg') !important;
    background-size: 5px;
    background-position: center;
  }
}
.blue-input {
  background-color: $primary-200;
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

.blue-input::placeholder {
  color: #cce5ff; /* Lighter blue for placeholder text */
}

.blue-input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Blue glow on focus */
}
.custom-input-container {
  display: flex;
  align-items: center;
  position: relative;
  background-color: $primary-200;
  border-radius: 4px;
}

.custom-input {
  flex: 1;
  background: none;
  border: none;
  color: white;
  text-align: center;
  padding: 0.75rem 1.5rem; /* Adjust the padding as needed */
}

.custom-input:focus {
  outline: none;
}

.custom-input-label {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 12px;
  color: white;
}

.chevron {
  position: absolute;
  width: 15px;
  height: 15px;
  border: solid white;
  border-width: 0 1px 1px 0;
  display: inline-block;
  cursor: pointer;
  padding:3px;
}

.chevron-left {
  left: 24px;
  transform: rotate(135deg);
}

.chevron-right {
  right: 24px;
  transform: rotate(-45deg);
}

.chevron:hover {
  color: #007bff; /* Change color on hover */
}
.loan-calculator {
  display: flex;
  flex-wrap: wrap;
}

.results-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
}

.result-box {
  background-color: #e9ecef;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}
