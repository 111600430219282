@import './fonts';
//Common Overwrites.. 

$primary: #07278A;
$primary-200: #2C479C;

$secondary: #00DCF5;
$secondary-500: #F2FAFF;
$secondary-400: #C7E9FF;
$secondary-300: #8BC9EF;
$secondary-200: #99F4FF;

$info: #FCB701;

$warning: #DC3545;

$success: #165322;

$dark: #101010;

$gray: #545454;
$light-gray: #BFBFBF;
$light-gray-50: #E7E7E7;
$light-gray-25: #F2F2F2;

$light: #FFFFFF;

$transparent: transparent;

$theme-colors: (
  "primary":$primary,
  "primary-200":$primary-200,
  "secondary":$secondary,
  "secondary-500":$secondary-500,
  "secondary-400":$secondary-400,
  "secondary-300":$secondary-300,
  "secondary-200":$secondary-200,
  "info":$info,
  "warning":$warning,
  "success":$success,
  "dark":$dark,
  "gray":$gray,
  "light-gray":$light-gray,
  "light-gray-50": $light-gray-50,
  "light-gray-25": $light-gray-25,
  "light":$light,
  "transparent":$transparent,
);

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (0: 0,
    4: ($spacer * .25),
    8: ($spacer * .5),
    16: ($spacer),
    24: ($spacer * 1.5),
    32: ($spacer * 2),
    40: ($spacer * 2.5),
    48: ($spacer * 3),
    56: ($spacer * 3.5),
    64: ($spacer * 4),
    72: ($spacer * 4.5),
    80: ($spacer * 5),
    88: ($spacer * 5.5),
    96: ($spacer * 6),
    104: ($spacer * 6.5),
    112: ($spacer * 7),
    120: ($spacer * 7.5),
    128: ($spacer * 8),
    136: ($spacer * 8.5),
    144: ($spacer * 9),
    152: ($spacer * 9.5),
    168: ($spacer * 10.5),
  ),
  $spacers
);

// $body-color: $black;
// $dropdown-link-color: $black;
// $dropdown-link-hover-color: $white;
// $dropdown-link-hover-bg: $primary;
$navbar-hover-color: $info;
// $grid-gutter-width: 32px;
// $input-border-color: $black;
$small-font-size: 12px;
$font-size-base: .8125rem;
$lead-font-size: 20px;
$h5-font-size: 24px;
$h4-font-size: 32px;
$h3-font-size: 36px;
$h2-font-size: 40px;
$h1-font-size: 48px;
$link-font-size: 16px;
$font-weight-semi-bold: 600;
// $headings-line-height: 1.25;
// $btn-border-radius: 24px;
// $btn-border-radius-lg: 24px;
// $btn-border-radius-sm: 24px;
// $card-border-radius: 16px;
// $input-border-radius: 0px;
$font-family-sans-serif: $red-hat;
// $font-family-monospace: avenir;
// $enable-responsive-font-sizes: true;
$accordion-border-width: 0px;
$accordion-button-padding-y: map-get($map: $spacers, $key: 8);
$accordion-button-active-color: $gray;
$accordion-button-active-bg: $secondary-500;
$accordion-button-bg: $secondary-500;
$accordion-button-color: $gray;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px
) !default;